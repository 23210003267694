
import  {defineComponent} from 'vue';
import { /* AboController, UserController, */ PaymentController } from '@/controller';
import { PageOverlayLoader } from '@/components';

export default defineComponent({
    name: "PaymentStatus",
    components: { PageOverlayLoader },
    data(){
        return{
            checkoutId: '',
            resourcePath: '',
            resultCode: '',
            resultDescription: '',
            timestamp: '',
            paymentSuccessfully: false,
            paymentPending: false
        }
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.checkoutId = PaymentController.checkoutId;
        this.resourcePath = PaymentController.resourcePath;
        this.checkPaymentStatus();
    },
    methods: {
        async checkPaymentStatus() {
            console.log('checkoutId', this.checkoutId, 'resourcePath', this.resourcePath)
            if (this.checkoutId && this.resourcePath) {
                const res : any = await PaymentController.checkPaymentStatus({ checkoutId: this.checkoutId, resourcePath: this.resourcePath });
                if(res && PaymentController.payment.successfully){
                    this.$router.push('/manageabo')
                }
            }
            setTimeout(() => {
                this.checkPaymentStatus();
           }, 5000)
        },
    }  
});
